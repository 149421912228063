* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-000000: #000000;
  --color-ffffff: #ffffff;
  --color-d3d3d3: #d3d3d3;
  --color-3b4149: #56585c;
  --color-007600: #007600;
  --color-bf4800: #bf4800;
  --color-013476: #013476;
  --color-febd69: #febd69;
  --color-f5f5f7: #f5f5f7;
  --color-dddddd: #dddddd;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-232f3e: #232f3e;
}

.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.card-wrapper {
  background-color: #fff;
  box-shadow: 0 0 10px #fff !important;
  padding: 15px;
}

body {
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  color: #febd69;
}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.error {
  color: rgb(196, 2, 2);
  font-size: 12px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-013476);
  color: #fff;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}

.button:hover {
  background-color: var(--color-232f3e);
  color: #fff;
}

/* Header */
.header-top-strip {
  border-bottom: var(--color-3b4149);
  background-color: #adadad;
}
.nav-mainbar {
  background-color: var(--color-ffffff) !important;
  border-bottom: 1px solid var(--color-ffffff);
}
.nav-mainbar .col-6 {
  width: 100% !important;
}
.nav-mainbar select {
  cursor: pointer;
}

.nav-upper {
  background-color: var(--color-d3d3d3);
}

.nav-upper .input-group-text {
  background-color: var(--color-013476);
}

.nav-upper.header-upper-links img {
  width: 30px;
  height: 30px;
}
.nav-upper span.badge {
  color: #f70404;
  top: 0px;
  right: 10px;
  font-size: 20px;
}
.nav-upper span.badge:hover {
  background-color: var(--color-232f3e);
}

.nav-mainbar .menu-links a,
.nav-mainbar .dropdown button {
  color: #000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3s;
}
.nav-mainbar .dropdown {
  background-color: var(--color-ffffff);
}
.nav-mainbar .dropdown button:focus {
  box-shadow: none;
}

.nav-mainbar .dropdown-menu {
  background-color: #fff;
  width: 100%;
  transform: translate3d(0px, 57px 0px) !important;
}

.nav-mainbar .dropdown-item {
  padding: 10px;
  margin-bottom: 3px;
}

.nav-mainbar .dropdown-item:hover {
  background-color: transparent;
  color: #febd69;
}

/* Dhuma Header */

/* footer */
footer {
  background-color: var(--color-d3d3d3);
}
footer > div:where(:last-child) {
  border-top: 1px solid var(--color-3b4149);
}
footer .input-group-text {
  background-color: var(--color-013476);
  color: var(--color-f5f5f7);
  padding: 8px 17px;
}

/* dhuma footer */

/* Homepage */
.main-banner-content {
  top: 15px;
  left: 5%;
}

.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.main-banner-content h5 {
  font-size: 42px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -2px;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4;
  color: var(--color-3b4149);
}

/* BANNER */

.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

.categories {
  background-color: #fff;
  box-shadow: 0 0 10px #fff;
  padding: 15px;
}

.categories>div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}

.categories>div>div {
  width: 60%;
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4) {
  border-bottom: 1px solid var(--color-f5f5f7);
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3) {
  border-right: 1px solid var(--color-f5f5f7);
}

.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7) {
  border-right: 1px solid var(--color-f5f5f7);
}

/* Categories */

.blog-card {
  background-color: #fff;
  border-radius: 10px;
}

.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-content {
  padding: 15px;
}

.blog-content p.date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}

.blog-content h5 {
  font-size: 18px;
  color: var(--color-000000);
}

.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}

/* Blog PART */

.product-card {
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 300px;
  min-width: 150px;
}

.product-card .product-image {
  height: 170px;
}

.product-card .product-image img {
  object-fit: contain;
}

.product-card .product-image>img:last-child {
  display: none;
}

.product-card:hover .product-image>img:nth-child(1) {
  display: none;
}

.product-card:hover .product-image>img:last-child {
  display: block;
}

.product-card:hover .action-bar {
  right: 15px;
}

.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 11px;
}

.product-card .product-details h5 {
  font-size: 13px;
  color: var(--color-1c1c1b);
}

.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}

.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}

.action-bar {
  top: 35px;
  right: -20px;
  transition: 0.3s;
}

.wishlist-icon {
  top: 2%;
  right: 15px;
}

/* PRODUCT CARD */

.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #fff;
}

/* Special Product */

.famous-card .famous-content {
  top: 10%;
  left: 5%;
}

.famous-card img {
  border-radius: 10px;
}

.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.famous-content h6 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: #fff;
}

.famous-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: #fff;
}

/* Famous Card */

.filter-card, .sub-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 15px 25px;
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}

.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}

.filter-card .form-check-input:checked {
  background-color: var(--color-007600);
  border-color: var(--color-febd69);
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}

.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}

.random-products:first-child {
  border-bottom: 1px solid var(--color-f5f5f7);
}

.filter-sort-grid {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.gr-12 {
  width: 100%;
}

.gr-6 {
  width: 49%;
}

.gr-4 {
  width: 32%;
}

.gr-3 {
  width: 24%;
}

.gr-12 .product-card {
  display: flex;
  gap: 20px;
}

.gr-6 .product-image {
  width: 100%;
}

.gr-6 .product-image img {
  margin: auto;
  display: block;
}

.gr-12 .product-card .product-image {
  width: 270px;
  height: auto;
}

.gr-12 .action-bar {
  top: 17%;
}
@media only screen and (max-width: 600px) {
  [class*='col-3'] {
      width: 49%;
    }
  
    [class*='col-3'] {
      width: 49%;
    }
}
@media only screen and (max-width: 400px) {
  [class*='col-3'] {
    width: 49%;
  }

    [class*='col-3'] {
    width: 49%;
  }
}

/* Our Store */

.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: #fff;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address, .contact-inner-wrapper a, .contact-inner-wrapper p {
  color: var(--color-777777);
}
.compare-product-card {
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 10px;
}

/* Contact */

/* Dhuma Homepage */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control {
  height: 50px;
  background-color: var(--color-ffffff);
  color: var(--color-3b4149);
  font-size: 14px;
  border-radius: 5px;
  border-color: var(--color-d3d3d3);
}
.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: #19037a;
}
.signup {
  background-color: var(--color-1c1c1b);
  color: var(--color-f5f5f7);
  border: none;
}
.signup:hover {
  background-color: var(--color-013476);
}

/* Auth Card */

.policy {
  background-color: #fff;
  padding: 30px;
}

/* Policy Pages */

.description-wrapper h4, .review-wrapper h3 {
  font-size: 26px;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}
.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.reviews-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.reviews-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-heads p, .review-heads a {
  font-size: 14px;
  color: var(--color-777777);
}
.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}
.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review p {
  font-size: 14px;
  color: var(--color-777777);
}
.main-product-image {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.main-product-image img {
  width: 100% !important;
  height: 600px !important;
  object-fit: cover;
}
.other-product-images {
  background-color: #fff;
  padding: 20px;
}
.other-product-images > div {
  padding: 20px;
  width: 22%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-image .js-image-zoom__zoomed-image {
  left: 50px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}
.main-product-details {
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-details h3.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.main-product-details p.price {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.main-product-details a.review-btn, .t-review {
  color: var(--color-777777);
  font-size: 13px;
}
.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}
.product-data {
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
}
.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}

/* Main Product Page */

.cart-header, .cart-data {
  border-bottom: 1px solid #ddd;
}
.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}
.cart-col-1 {
  width: 40%;
}
.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}
.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}
.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

/* Cart Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}

/* Shipping Page */
